/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.expandedRow-table {
  margin: -12px -8px;
  background: #f4f4f4;
}
.expandedRow-table .expandedRow-table-row {
  display: grid;
  grid-template-columns: 20% 1fr 200px 15%;
}
.expandedRow-table .expandedRow-table-head {
  position: relative;
  overflow-y: scroll;
  font-weight: bold;
}
.expandedRow-table .expandedRow-table-head::-webkit-scrollbar {
  background-color: transparent;
}
.expandedRow-table .expandedRow-table-body .expandedRow-table-row:last-child .expandedRow-table-col {
  border-bottom: 0;
}
.expandedRow-table .expandedRow-table-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 5px;
  border-bottom: 1px solid #dfdfdf;
}
.expandedRow-table .expandedRow-table-body .expandedRow-table-col {
  padding: 12px 8px;
}
.expandedRow-table .expandedRow-table-head {
  font-weight: bold;
}
